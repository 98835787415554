import Http from './http';
import { apiPermission } from '@/decorator/api-permission';
import { IPage, IQueryDTO, IId } from '@/types/api/common';
import { ITaskDetail, TaskMediaCount } from '@/types/api/task';

export default class Task {
  /**
   * 创建
   * @returns
   */
  @apiPermission(['task:task:create'], '')
  static create(data: any): Promise<string> {
    return Http.post('/m.api/admin.task/create', data);
  }

  /**
   * 编辑
   * @returns
   */
  @apiPermission(['task:task:edit'], '')
  static edit(data: any): Promise<string> {
    return Http.post('/m.api/admin.task/edit', data);
  }

  /**
   * 删除
   * @returns
   */
  @apiPermission(['task:task:delete'], '')
  static delete(data: { taskId: number }): Promise<any> {
    return Http.post('/m.api/admin.task/delete', data);
  }

  /**
   * 删除（批量）
   * @returns
   */
  @apiPermission(['task:task:delete'], '')
  static deleteBatch(data: number[]): Promise<any> {
    return Http.post('/m.api/admin.task/deleteBatch', {
      taskIds: data,
    });
  }

  /**
   * 列表（所有）
   * @returns
   */
  @apiPermission(['task:task:list'], {})
  static list(data: any): Promise<IPage<any>> {
    return Http.post('/m.api/admin.task/list', {
      queryDTO: data,
    });
  }

  /**
   * 列表（所有）
   * @returns
   */
  @apiPermission(['task:task:list'], {})
  static list1(data: any): Promise<IPage<any>> {
    return Http.post(
      '/m.api/admin.task/list',
      {
        queryDTO: data,
      },
      {
        // @ts-ignore 检测到未登录，不进行任何处理
        notAuthLogin: true,
        // @ts-ignore 检测到返回错误信息，不处理
        hideErrorMsg: true,
      },
    );
  }

  /**
   * 获取某文件夹正在进行的任务
   * @returns
   */
  static listDoingByFolder(data: any): Promise<any> {
    return Http.post('/m.api/admin.task/listDoingByFolder', data);
  }

  /**
   * 列表（我的）
   * @returns
   */
  @apiPermission(['task:task:listmy'], {})
  static listMy(data: any): Promise<IPage<any>> {
    const params: IQueryDTO<any> = {
      queryDTO: data,
    };
    return Http.post('/m.api/admin.task/listMy', params);
  }

  /**
   * 列表（我的）
   * @returns
   */
  @apiPermission(['task:task:listmy'], {})
  static listMy1(data: any): Promise<IPage<any>> {
    const params: IQueryDTO<any> = {
      queryDTO: data,
    };
    return Http.post('/m.api/admin.task/listMy', params, {
      // @ts-ignore 检测到未登录，不进行任何处理
      notAuthLogin: true,
      // @ts-ignore 检测到返回错误信息，不处理
      hideErrorMsg: true,
    });
  }

  /**
   * 详情
   * @returns
   */
  @apiPermission(['task:task:detail'], {})
  static detail(data: IId): Promise<ITaskDetail> {
    return Http.post('/m.api/admin.task/detail', data);
  }

  /**
   * 提醒
   * @returns
   */
  @apiPermission(['task:task:reminder'], '')
  static reminder(data: number): Promise<ITaskDetail> {
    return Http.post('/m.api/admin.task/reminder', { taskId: data });
  }
  /**
   * 统计数量
   * @returns
   */
  static taskMediaCount(data: number): Promise<TaskMediaCount> {
    return Http.post('/m.api/admin.task/taskMediaCount', {
      taskId: data,
      // onlyMy: true
    });
  }

  /**
   * 任务Dashboard（统计）
   * @returns
   */
  static taskCount(data: any): Promise<any> {
    return Http.post('/m.api/admin.task/taskCount', data);
  }
}
