import { defineStore, StoreDefinition } from 'pinia';
import { IUserInfo } from '@/types/api/admin';
import { RouteRecordRaw } from 'vue-router';
import { IShareOpenInfo } from '@/types/api/share';
import Storage from '@/utils/storage';

interface AccountReset {
  account: string;
  code: string;
}

/**
 * 分享信息
 */
interface ShareInfo {
  // 是否通过校验
  pass: boolean;
  // 分享信息
  info: IShareOpenInfo;
  // 分享密码
  password: string;
  // 分享ID
  uuid: string;
}

interface AuthState {
  token: string;
  userInfo: IUserInfo;
  // 授权的路由数据
  authRoutes: RouteRecordRaw[];
  // 登录重置账号
  loginResetAccount: AccountReset;
  // 分享信息
  share: ShareInfo;
  avatar: string;
}

export const useAuthStore: StoreDefinition<string, AuthState> = defineStore('auth', {
  state: (): AuthState => {
    return {
      token: '',
      userInfo: {} as IUserInfo,
      authRoutes: [],
      loginResetAccount: {
        account: '',
        code: '',
      },
      share: {
        pass: false,
        info: {} as IShareOpenInfo,
        password: '',
        uuid: '',
      },
      avatar: 'avatar1',
    };
  },
  getters: {
    getToken(state: AuthState): string {
      return state.token || '';
    },
    getName(state: AuthState): string {
      return state.userInfo.realname || '';
    },

    getUser(state: AuthState): any {
      return state.userInfo || {};
    },

    getUserId(state: AuthState): string {
      return (state.userInfo || {}).id || '';
    },

    getAuthRoutes(state: AuthState): RouteRecordRaw[] {
      return state.authRoutes || [];
    },
    getAuthBtnPermissions(state: AuthState): string[] {
      return state.userInfo.perms || [];
    },
    getAllPermissions(state: AuthState): string[] {
      return state.userInfo.perms || [];
    },
    getLoginResetAccount(state: AuthState): AccountReset {
      return state.loginResetAccount || {};
    },
    getShare(state: AuthState): ShareInfo {
      return state.share;
    },
    getAvatar(state: AuthState): string {
      return state.userInfo.avatarUrl || state.avatar || 'avatar1';
      // const id = this.userInfo.id || '0';
      // const avatar = Storage.getItem(`defaultAvatar_${id}`);
      // return state.avatar || avatar || 'avatar1';
    },
  },
  actions: {
    setToken(token: string): void {
      token = token || '';
      this.token = token;
    },
    setUser(info: IUserInfo | never): void {
      info = info || {};
      this.userInfo = info;
    },
    setAuthRoutes(routes: RouteRecordRaw[]): void {
      routes = routes || [];
      this.authRoutes = routes;
    },
    setLoginResetAccount(account: AccountReset): void {
      this.loginResetAccount = account || {
        account: '',
        code: '',
      };
    },
    setShare(pass: boolean, info: IShareOpenInfo | {}, password: string, uuid: string): void {
      this.share.pass = pass;
      this.share.info = info;
      this.share.password = password;
      this.share.uuid = uuid;
    },
    setAvatar(key: string) {
      this.avatar = key;
      this.userInfo.avatarUrl = key;
      const id = this.userInfo.id || '0';
      Storage.setItem(`defaultAvatar_${id}`, key);
    },
  },
  persist: {
    enabled: true,
  },
});
